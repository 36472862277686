import React from 'react';
import { S } from './WorkRegisterFilterDropdown.styles';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { FilterPersonnel } from '../../../../../../traits/api/workRegisters/useGetAllPersonnel';

type Props = {
  filter: FilterPersonnel;
  setFilter: (value: FilterPersonnel) => void;
  title?: string;
};

export const WorkRegisterFilterDropdown: React.FC<Props> = (props) => {
  const sortingOptions = [
    {
      label: 'Inget',
      value: 'none',
    },
    {
      label: 'Ej inloggade i EPL',
      value: 'NO_EPL',
    },
    {
      label: 'ID06-kort avvikelser',
      value: 'ID06_CARD',
    },
    {
      label: 'Saknar legitimation',
      value: 'ID_CARD',
    },
    {
      label: 'Saknar Safe Construction Training',
      value: 'NO_SAFE',
    },
    {
      label: 'Avvikelse AT/UT/PUT',
      value: 'AT_UT',
    },
    {
      label: 'Avvikelse personlig säkerhetsutrustning',
      value: 'SAFTEY',
    },
  ] as const;

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <S.SortButton aria-label="Customise options">
          <S.H6 color="secondary">Filtrera</S.H6>
          <S.Icon src="Sort" color="secondary" />
        </S.SortButton>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <S.DropDown sideOffset={5}>
          {sortingOptions.map((item) => (
            <S.DropDownItem
              key={item.value}
              onClick={() => props.setFilter(item.value)}
            >
              {props.filter === item.value && (
                <S.Icon
                  src="CheckCircle"
                  width={20}
                  height={20}
                  color="secondary"
                />
              )}

              <S.DropDownItemText color="secondary">
                {item.label}
              </S.DropDownItemText>
            </S.DropDownItem>
          ))}
        </S.DropDown>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
